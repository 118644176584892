import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'

import { Box, Text, Link, Flex, useColorMode } from '@chakra-ui/react'
import { Icon } from '@pnld/components-web'

import { IBreadcrumbsProps } from './types'

const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({ items }) => {
  const { colorMode } = useColorMode()

  const lastItemIndex = items.length - 1
  const currentItem = lastItemIndex >= 0 ? items[lastItemIndex].label : null

  const crumbColor = 'blue.600'
  const selectedCrumbColor = 'blue.500'

  return (
    <Flex align="flex-start" gap={2}>
      <Box>
        <Link as={ReactRouterLink} to="/" mr={2} color="#23415B">
          <Icon name="house-chimney" size="2xs" inverse />
        </Link>
        <Icon name="chevron-right" size="2xs" variant="neutral_light_1" />
      </Box>
      {items.map((item, index) => (
        <Box key={`breadcrum-item-${index}`}>
          <Link
            as={ReactRouterLink}
            to={item.href || '#'}
            color="#23415B"
            fontSize="2xs"
            fontWeight="medium"
            lineHeight="150%"
            marginRight={index < items.length - 1 ? 2 : '0'}
          >
            {item.label}
          </Link>
          {index < items.length - 1 && (
            <Icon name="chevron-right" size="2xs" variant="neutral_light_1" />
          )}
        </Box>
      ))}
    </Flex>
  )
}

export default Breadcrumbs
