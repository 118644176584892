import React from 'react'
import { NavLink as ReactRouterLink } from 'react-router-dom'

import { Link, styled } from '@pnld/components-web'

import S from '../../styles'
import { IHeaderNavItemProps } from '../../types'

const NeedsLink = styled(Link, {})

const HeaderNavItem = ({
  onClose,
  to,
  children,
  ...rest
}: IHeaderNavItemProps) => {
  return (
    <NeedsLink
      as={ReactRouterLink}
      to={to}
      onClick={onClose}
      _activeLink={{
        bg: '#E1B672',
      }}
    >
      <S.HeaderItem
        _hover={{
          color: '#D27723',
          bg: '#FFFFFF',
        }}
        {...rest}
      >
        {children}
      </S.HeaderItem>
    </NeedsLink>
  )
}

export default HeaderNavItem
