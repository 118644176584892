import React from 'react'

import { Box, Stack, useMultiStyleConfig } from '@chakra-ui/react'
import { Icon, Typography } from '@pnld/components-web'

import { ITotalizerDisplayProps } from './types'

const TotalizerDisplay = ({
  number,
  label,
  variant = 'primary',
}: ITotalizerDisplayProps) => {
  const styles = useMultiStyleConfig('TotalizerDisplay', { variant })

  return (
    <Stack __css={styles.cardBox}>
      <Icon name="" {...styles.cardIcon} />
      <Typography variant="d-sm" color="brand.primary.dark_1">
        {number}
      </Typography>
      <Typography variant="h-2xs" color="brand.primary.dark_1">
        {label}
      </Typography>
    </Stack>
  )
}

export default TotalizerDisplay
