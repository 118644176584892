import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { SimpleGrid } from '@chakra-ui/react'
import {
  Stack,
  Flex,
  Tag,
  Typography,
  Button,
  TColumnDef,
  DataGrid,
} from '@pnld/components-web'

import SlidesDisplay from '@/components/Display/Slides'
import TotalizerDisplay from '@/components/Display/Totalizer'

import EducationStagesTable from './Tables/EducationStages'

const PredictionsResults = () => {
  const navigate = useNavigate()

  const slidesItems = [
    {
      title: 'Etapas de ensino',
      content: <EducationStagesTable />,
    },
  ]

  return (
    <Stack gap={6}>
      <Stack gap={4} p={4} boxShadow="md" borderRadius="base">
        <Typography variant="h-xs" color="brand.primary.dark_2">
          Predição - 2024
        </Typography>
        <Flex direction="column">
          <Typography variant="b-sm">
            <strong>Entidades Preditas:</strong> 115271
          </Typography>
          <Typography variant="b-sm">
            <strong>Esfera:</strong> Pública
          </Typography>
          <Typography variant="b-sm">
            <strong>Etapa de ensino:</strong> Ensino fundamental
          </Typography>
        </Flex>
        <Flex justify="space-between" gap={4}>
          <Button
            label="Voltar"
            variant="tertiary"
            iconName="chevron-left"
            onClick={() => navigate('/predicoes')}
          />
          <Flex gap={4}>
            <Button
              label="Baixar predição completa"
              variant="secondary"
              iconName="file-download"
            />
            <Button label="Baixar predição resumida" iconName="file-download" />
          </Flex>
        </Flex>
      </Stack>
      <Stack gap={4} p={4} boxShadow="md" borderRadius="base">
        <Stack>
          <Typography variant="h-xs" color="brand.primary.dark_2">
            Pré-visualização de dados
          </Typography>
          <Typography variant="b-sm">
            Consulte abaixo totais de dados da predição solicitada.
          </Typography>
        </Stack>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} gap={4}>
          <TotalizerDisplay number={865} label="Censo" />
          <TotalizerDisplay number={875} label="H1" />
          <TotalizerDisplay number={875} label="H2" />
          <TotalizerDisplay
            number={40}
            label="Total de entidades"
            variant="highlight"
          />
        </SimpleGrid>
        <SlidesDisplay items={slidesItems} />
      </Stack>
    </Stack>
  )
}

export default PredictionsResults
