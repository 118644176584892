export const childEductionOptions = [
  {
    value: 'Creche (0 A 3 Anos)',
    label: 'Creche (0 A 3 Anos)',
  },
  {
    value: 'Unificada (0 a 5 anos)',
    label: 'Unificada (0 a 5 anos)',
  },
  {
    value: 'Pre-Escola (4 E 5 Anos)',
    label: 'Pre-Escola (4 E 5 Anos)',
  },
]

export const basicEducationOptions = [
  {
    value: 'Ensino Fundamental - 1º Ano',
    label: 'Ensino Fundamental - 1º Ano',
  },
  {
    value: 'Ensino Fundamental - 2º Ano',
    label: 'Ensino Fundamental - 2º Ano',
  },
  {
    value: 'Ensino Fundamental - 3º Ano',
    label: 'Ensino Fundamental - 3º Ano',
  },
  {
    value: 'Ensino Fundamental - 4º Ano',
    label: 'Ensino Fundamental - 4º Ano',
  },
  {
    value: 'Ensino Fundamental - 5º Ano',
    label: 'Ensino Fundamental - 5º Ano',
  },
  {
    value: 'Ensino Fundamental - 6º Ano',
    label: 'Ensino Fundamental - 6º Ano',
  },
  {
    value: 'Ensino Fundamental - 7º Ano',
    label: 'Ensino Fundamental - 7º Ano',
  },
  {
    value: 'Ensino Fundamental - 8º Ano',
    label: 'Ensino Fundamental - 8º Ano',
  },
  {
    value: 'Ensino Fundamental - 9º Ano',
    label: 'Ensino Fundamental - 9º Ano',
  },
  {
    value: 'Ensino Fundamental - Correção de Fluxo',
    label: 'Ensino Fundamental - Correção de Fluxo',
  },
  {
    value: 'Ensino Fundamental - Multi',
    label: 'Ensino Fundamental - Multi',
  },
  {
    value: 'Educação Infantil e Ensino Fundamental Multietapa',
    label: 'Educação Infantil e Ensino Fundamental Multietapa',
  },
]

export const highSchoolOptions = [
  {
    value: 'Ensino Medio - Não Seriada',
    label: 'Ensino Medio - Não Seriada',
  },
  {
    value: 'Ensino Medio - 1ª Serie',
    label: 'Ensino Medio - 1ª Serie',
  },
  {
    value: 'Ensino Medio - 2ª Serie',
    label: 'Ensino Medio - 2ª Serie',
  },
  {
    value: 'Ensino Medio - 3ª Serie',
    label: 'Ensino Medio - 3ª Serie',
  },
  {
    value: 'Ensino Medio - 4ª Serie',
    label: 'Ensino Medio - 4ª Serie',
  },
  {
    value: 'Ensino Medio - Normal/Magisterio 1ª Serie',
    label: 'Ensino Medio - Normal/Magisterio 1ª Serie',
  },
  {
    value: 'Ensino Medio - Normal/Magisterio 2ª Serie',
    label: 'Ensino Medio - Normal/Magisterio 2ª Serie',
  },
  {
    value: 'Ensino Medio - Normal/Magisterio 3ª Serie',
    label: 'Ensino Medio - Normal/Magisterio 3ª Serie',
  },
  {
    value: 'Ensino Medio - Normal/Magisterio 4ª Serie',
    label: 'Ensino Medio - Normal/Magisterio 4ª Serie',
  },
  {
    value: 'Ensino Medio Tecnico Integrado - Não Seriada',
    label: 'Ensino Medio Tecnico Integrado - Não Seriada',
  },
  {
    value: 'Ensino Medio Tecnico Integrado - 1ª Serie',
    label: 'Ensino Medio Tecnico Integrado - 1ª Serie',
  },
  {
    value: 'Ensino Medio Tecnico Integrado - 2ª Serie',
    label: 'Ensino Medio Tecnico Integrado - 2ª Serie',
  },
  {
    value: 'Ensino Medio Tecnico Integrado - 3ª Serie',
    label: 'Ensino Medio Tecnico Integrado - 3ª Serie',
  },
  {
    value: 'Ensino Medio Tecnic Integrado - 4ª Serie',
    label: 'Ensino Medio Tecnic Integrado - 4ª Serie',
  },
]

export const technicalCourseOptions = [
  {
    value: 'Curso Tecnico Integrado Na Modalidade Eja',
    label: 'Curso Tecnico Integrado Na Modalidade Eja',
  },
  {
    value: 'Curso Tecnico - Concomitante',
    label: 'Curso Tecnico - Concomitante',
  },
  {
    value: 'Curso Tecnico - Subsequente',
    label: 'Curso Tecnico - Subsequente',
  },
  {
    value: 'Curso Técnico Misto',
    label: 'Curso Técnico Misto',
  },
]

export const ejaOptions = [
  {
    value: 'Eja - Ensino Fundamental - Anos Finais',
    label: 'Eja - Ensino Fundamental - Anos Finais',
  },
  {
    value: 'Eja - Ensino Fundamental - Anos Iniciais',
    label: 'Eja - Ensino Fundamental - Anos Iniciais',
  },
  {
    value: 'Eja - Ensino Fundamental - Anos Iniciais e Anos Finais',
    label: 'Eja - Ensino Fundamental - Anos Iniciais e Anos Finais',
  },
  {
    value: 'Eja - Ensino Medio',
    label: 'Eja - Ensino Medio',
  },
]
