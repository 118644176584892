import React from 'react'
import { Outlet } from 'react-router-dom'

import { Box } from '@chakra-ui/react'

const Panel = () => {
  return (
    <Box>
      <Outlet />
    </Box>
  )
}

export default Panel
