import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Field, FieldProps, FormikValues } from 'formik'
import * as yup from 'yup'

import { Text } from '@chakra-ui/react'
import {
  Icon,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
  Switch,
  Select,
  Divider,
  Box,
  Stack,
  Flex,
  useCheckboxGroup,
  Spinner,
} from '@pnld/components-web'

import {
  childEductionOptions,
  basicEducationOptions,
  highSchoolOptions,
  technicalCourseOptions,
  ejaOptions,
} from './data'

const sphereCheckboxesValues = ['publica', 'privada_c', 'privada_nc']
const stageCheckboxesValues = [
  'ensino_infantil',
  'ensino_fundamental_inicial',
  'ensino_fundamental_final',
  'ensino_medio',
  'curso_tecnico',
  'eja',
]

const Predictions = () => {
  const navigate = useNavigate()
  const [allEntities, setAllEntities] = React.useState(false)
  const [advancedSearch, setAdvancedSearch] = React.useState(false)
  const [loadingResults, setLoadingResults] = React.useState(false)
  const {
    value: checkboxesValues,
    getCheckboxProps,
    setValue: setCheckboxes,
  } = useCheckboxGroup({
    defaultValue: [],
    onChange: values => {},
  })

  const yearsOptions = Array.from({ length: 4 }).map((_, i) => ({
    value: `${2020 + i}`,
    label: `${2020 + i}`,
  }))

  const handleSearch = (values: FormikValues) => {
    setLoadingResults(true)
    setTimeout(() => {
      setLoadingResults(false)
      navigate('resultado')
    }, 2000)
    // TODO: Implement search
  }

  return (
    <Stack gap={6}>
      <Flex
        p={4}
        gap={4}
        bg="brand.primary.light_2"
        borderRadius="md"
        align="center"
      >
        <Icon name="info-circle" size="xs" />
        <Text fontWeight="semibold" color="brand.neutral.dark_2">
          Predição disponível até o ano de 2024
        </Text>
      </Flex>
      <Stack gap={4} boxShadow="md" p={4} borderRadius="base">
        <Typography variant="h-xs" color="brand.primary.dark_2">
          Predições
        </Typography>
        <Stack gap={4}>
          <Form
            onSubmit={handleSearch}
            initialValues={{
              year: '',
              all_entities: false,
            }}
            validationSchema={yup.object().shape({
              year: yup.string().required('Obrigatório'),
              child_education: yup.array().when('advanced_search', {
                is: true,
                then: s => s.required('Obrigatório'),
              }),
            })}
          >
            <Stack gap={4}>
              <Flex justify="space-between">
                <Box flex={2}>
                  <Select
                    name="year"
                    options={yearsOptions}
                    placeholder="Selecione um ano de atendimento"
                  />
                </Box>
                <Flex gap={2} flex={3} justify="flex-end">
                  <Button
                    label="Pesquisa avançada"
                    variant="tertiary"
                    disabled={allEntities}
                    onClick={() => {
                      setAdvancedSearch(!advancedSearch)
                      if (!advancedSearch) {
                        setCheckboxes(stageCheckboxesValues)
                      } else {
                        setCheckboxes([])
                      }
                    }}
                  />
                  <Button
                    type="submit"
                    label="Buscar"
                    variant="secondary"
                    iconName="search"
                  />
                </Flex>
              </Flex>
              <Flex gap={4}>
                <Typography variant="b-sm" color="brand.neutral.dark_2">
                  Esfera:
                </Typography>
                <Checkbox
                  {...getCheckboxProps({
                    value: 'publica',
                    disabled: allEntities,
                  })}
                  label="Pública"
                />
                <Checkbox
                  {...getCheckboxProps({
                    value: 'privada_c',
                    disabled: allEntities,
                  })}
                  label="Privada (conveniadas ao PNLD)"
                />
                <Checkbox
                  {...getCheckboxProps({
                    value: 'privada_nc',
                    disabled: allEntities,
                  })}
                  label="Privada (não conveniadas ao PNLD)"
                />
              </Flex>
              <Flex gap={4} flexWrap="wrap">
                <Typography variant="b-sm" color="brand.neutral.dark_2">
                  Etapa de ensino:
                </Typography>
                <Checkbox
                  {...getCheckboxProps({
                    value: 'ensino_infantil',
                    disabled: allEntities || advancedSearch,
                  })}
                  label="Ensino infantil"
                />
                <Checkbox
                  {...getCheckboxProps({
                    value: 'ensino_fundamental_inicial',
                    disabled: allEntities || advancedSearch,
                  })}
                  label="Ensino fundamental anos iniciais"
                />
                <Checkbox
                  {...getCheckboxProps({
                    value: 'ensino_fundamental_final',
                    disabled: allEntities || advancedSearch,
                  })}
                  label="Ensino fundamental anos finais"
                />
                <Checkbox
                  {...getCheckboxProps({
                    value: 'ensino_medio',
                    disabled: allEntities || advancedSearch,
                  })}
                  label="Ensino médio"
                />
                <Checkbox
                  {...getCheckboxProps({
                    value: 'curso_tecnico',
                    disabled: allEntities || advancedSearch,
                  })}
                  label="EJA"
                />
              </Flex>
              <Flex align="center" gap={2}>
                <Text fontWeight="semibold" fontSize="2xs">
                  Todas as entidades
                </Text>
                <Switch
                  name="all_entities"
                  size="lg"
                  disabled={advancedSearch}
                  onChange={e => {
                    setAllEntities(e.target.checked)
                    if (e.target.checked) {
                      setCheckboxes([
                        ...sphereCheckboxesValues,
                        ...stageCheckboxesValues,
                      ])
                    } else {
                      setCheckboxes([])
                    }
                  }}
                />
                <Typography variant="b-sm" color="brand.neutral.dark_2">
                  {allEntities ? 'Sim' : 'Não'}
                </Typography>
              </Flex>
              {advancedSearch && (
                <>
                  <Divider />
                  <Flex gap={4}>
                    <Select
                      label="Educação infantil"
                      name="child_education"
                      options={childEductionOptions}
                      placeholder="Selecione uma opção"
                      isMulti
                      multiValueLimitDisplayed={1}
                    />
                    <Select
                      label="Ensino fundamental"
                      name="basic_education"
                      options={basicEducationOptions}
                      placeholder="Selecione uma opção"
                      isMulti
                      multiValueLimitDisplayed={1}
                    />
                    <Select
                      label="Ensino médio"
                      name="high_school"
                      options={highSchoolOptions}
                      placeholder="Selecione uma opção"
                      isMulti
                      multiValueLimitDisplayed={1}
                    />
                  </Flex>
                  <Flex gap={4}>
                    <Select
                      label="Curso técnico"
                      name="technical_course"
                      options={technicalCourseOptions}
                      placeholder="Selecione uma opção"
                      isMulti
                      multiValueLimitDisplayed={1}
                    />
                    <Select
                      label="EJA"
                      name="eja"
                      options={ejaOptions}
                      placeholder="Selecione uma opção"
                      isMulti
                      multiValueLimitDisplayed={1}
                    />
                  </Flex>
                </>
              )}
            </Stack>
          </Form>
        </Stack>
      </Stack>
      {loadingResults ? (
        <Flex justify="center" align="center" gap={4}>
          <Spinner color="brand.primary.dark_1" size="md" />
          <Text fontSize="2xs" fontWeight="medium" color="brand.neutral.dark_2">
            Carregando...
          </Text>
        </Flex>
      ) : (
        <Stack
          px={8}
          py={12}
          gap={4}
          align="center"
          boxShadow="md"
          borderRadius="base"
        >
          <Icon name="search" size="md" shaped variant="neutral" />
          <Stack>
            <Typography
              variant="s-md"
              color="brand.neutral.dark_2"
              textAlign="center"
            >
              Nenhum resultado encontrado.
            </Typography>
            <Typography
              variant="b-lg"
              color="brand.neutral.dark_2"
              textAlign="center"
            >
              Verifique se os termos foram digitados corretamente e se filtros
              que possam interferir na busca estão selecionados.
            </Typography>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

export default Predictions
