import React, { useContext, createContext, useState } from 'react'

const AppContext = createContext({
  isMini: false,
  toggleSidebarState: () => {},
})

export const AppContextProvider = ({ children }: React.PropsWithChildren) => {
  const [isMini, setIsOpen] = useState(false)

  const toggleSidebarState = () => {
    setIsOpen(!isMini)
  }

  return (
    <AppContext.Provider
      value={{ isMini: isMini, toggleSidebarState: toggleSidebarState }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useSidebar = () => {
  return useContext(AppContext)
}
