import React, { useMemo } from 'react'

import { TColumnDef, DataGrid } from '@pnld/components-web'

import { IEducationStage } from './types'

const tableData: Array<IEducationStage> = [
  {
    stage: 'Educacao Infantil - Creche (0 A 3 Anos)',
    total: 336,
    h1: 347,
    h2: 347,
  },
  {
    stage: 'Educacao Infantil - Pre-Escola (4 E 5 Anos)',
    total: 336,
    h1: 347,
    h2: 347,
  },
  {
    stage: 'Educacao Infantil - Creche (0 A 3 Anos)',
    total: 336,
    h1: 347,
    h2: 347,
  },
]

const EducationStagesTable = () => {
  const columns = useMemo<Array<TColumnDef<IEducationStage>>>(
    () => [
      {
        accessorKey: 'stage',
        header: 'Etapas de ensino - Educação Infantil',
      },
      {
        accessorKey: 'total',
        header: 'Censo',
      },
      {
        accessorKey: 'h1',
        header: 'H1',
      },
      {
        accessorKey: 'h2',
        header: 'H2',
      },
      // {
      //   accessorFn: row => new Date(row.sendDate).toLocaleDateString(),
      //   header: 'Data de envio',
      // },
    ],
    []
  )

  return (
    <>
      <DataGrid columns={columns} data={tableData} hasPagination={false} />
    </>
  )
}

export default EducationStagesTable
