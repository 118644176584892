import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Stack, Flex, Image, Typography, Button } from '@pnld/components-web'

import LogoFNDE from '@/assets/svg/fnde/mono.svg'
import LogoMEC from '@/assets/svg/mec/mono.svg'
import LogoNEES from '@/assets/svg/nees/mono.svg'
import LogoSPA from '@/assets/svg/spa/colorful.svg'

const AccessDenied = () => {
  const navigate = useNavigate()

  return (
    <Stack minH="100vh" bg="#D27723">
      <Flex justify="center" align="center" flex={1} p={{ base: 2, md: 0 }}>
        <Stack maxW="596px" bg="#FFFFFF" borderRadius="md" p={16} gap={16}>
          <Image src={LogoSPA} h="75px" />
          <Stack gap={4}>
            <Typography variant="s-md" color="red.600" textAlign="center">
              Usuário não identificado em nossa base.{' '}
            </Typography>
            <Typography variant="b-lg" textAlign="center" color="#0C326F">
              Nossa equipe revisou suas credenciais e, infelizmente, sua
              solicitação não foi autorizada. Verifique se usou as credenciais
              informadas no pré-cadastro ou entre em contato com seu
              coordenador.
            </Typography>
            <Flex justify="center">
              <Button
                label="Entrar com outro usuário"
                bg="#23415B"
                onClick={() => navigate('/login')}
              />
            </Flex>
          </Stack>
        </Stack>
      </Flex>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        justify="center"
        gap={6}
        pb={8}
        align="center"
      >
        <Image src={LogoNEES} h="80px" />
        <Image src={LogoFNDE} h={{ base: '32px', md: '40px' }} />
        <Image src={LogoMEC} h={{ base: '72px', md: '96px' }} />
      </Flex>
    </Stack>
  )
}

export default AccessDenied
