import { theme } from '@pnld/components-web'

import colors from './colors'
import components from './components'

const customTheme = {
  ...theme,
  components: {
    ...theme.components,
    ...components,
  },
  colors: {
    ...theme.colors,
    brand: {
      ...theme.colors.brand,
      ...colors,
    },
  },
}

export default customTheme
