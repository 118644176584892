import React from 'react'

import { Flex, Image, IconButton } from '@pnld/components-web'

import LogoSPA from '@/assets/svg/spa/mono.svg'

import { IMobileProps } from '../../types'

const MobileNav = ({ onOpen, sidebarWidth, ...rest }: IMobileProps) => {
  return (
    <Flex
      ml={{ base: 0, md: sidebarWidth }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      justify="space-between"
      bg="#D27723"
      borderBottomWidth="1px"
      borderBottomColor="gray.200"
      gap={4}
      {...rest}
    >
      <IconButton
        variant="tertiary"
        onClick={onOpen}
        aria-label="open menu"
        iconName="bars"
      />

      <Image
        height={{ base: '36px', sm: '48px' }}
        src={LogoSPA}
        alt="Logo SPA"
      />
    </Flex>
  )
}

export default MobileNav
